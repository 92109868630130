.IndividualSignup {
  padding-bottom: 24px;
  padding-top: 30px;

  li {
    padding-left: 1em;
    text-indent: -1.5em;
    margin-left: 1em;

    &:first-child {
      padding-top: 4px;
    }
  }

  .card {
    padding: 15px;
    margin-bottom: 16px;
    margin-top: 16px;
    background: #fafafa;
    border: 1px solid #eee;
    border-radius: 3px;
  }

  h4 {
    font-weight: 100;
    font-size: 36px;
    color: #0f629b;
    text-align: center;
    margin-bottom: 17px;
  }

  .tos-statement {
    padding-top: 24px;
  }

  .signup-box {
    background-color: #ffffff;
    border: solid 1px #d6d6d6;

    @media (max-device-width: 480px) {
      border-left-color: white;
      border-right-color: white;
      padding-top: 30px;
      padding-bottom: 30px;
    }

     @media (min-device-width: 480px) {
      padding-top: 68px;
      padding-bottom: 77px;
    }

    .alert {
      border-radius: 4px;
    }
    
    .coupon-label {
      font-weight: 100;
    }

    .input-addon {
      font-size: 16px;
      line-height: 13px;
      width: 2rem;
      padding-left: 8px;
      color: white;
      background: #4c6c82;
    }

    .stripe-logo {
      float: right;
    }

    .payment-col {
      padding-top: 4px;
    }

    .total {
      padding-top: 4px;
      background: #fafafa;
      border: 1px solid #eee;
      border-radius: 3px;
      padding: 15px;
      text-align: left;
    }

    i {
      float: right;
      font-style: normal;
      font-weight: bold;
    }
  }

  .form-control-label {
    font-weight: 100;
  }

  .btn-info {
    margin-top: 24px;
    padding: 16px 89px;
    margin-bottom: 24px;
  }

  .input-group-append {
    background: #0f629b;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    color: white;
    border-radius: 0px 4px 4px 0px;
    width: 38px;
    text-align: center;
  }

  .fa {
    margin-top: 7px;
  }

  .forgot-password {
    margin-top: 20px;
  }
}