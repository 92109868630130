.Footer {
  font-size: 14px;
  padding-top: 108px;
  padding-bottom: 108px;

  @media screen and (max-width: 768px) {
    text-align: center;
    padding-top: 78px;
    padding-bottom: 86px;
  }

  .logo {
    padding-right: 24px;
    margin-top: -4px;

    @media screen and (max-width: 768px) {
      margin-top: 0px;
      padding-right: 0px;
      padding-bottom: 36px;
    }
  }

  .copyright {
    color: rgb(59, 65, 69);

    @media screen and (max-width: 768px) {
      padding-bottom: 70px;
    }
  }

  a {
    color: black;
    font-weight: bold;
  }

  .footer-link {
    @media screen and (max-width: 768px) {
      margin-bottom: 24px;
    }
  }

  .disclaimer {
    padding-top: 1rem;
    font-size: 10px;

    @media screen and (max-width: 768px) {
      text-align: justify;
    }

    &:first-child {
      padding-top: 48px;
    }
  }
}