.About {
  .first-block {
    padding-top: 32px;
    background: #0f639c;
    background-image: url("/images/about-bg.svg");
    background-size: 70% auto;
    background-repeat: no-repeat;
    background-position-x: 63%;
    background-position-y: bottom;

    @media screen and (max-width: 767px) {
      background-size: 95%;
      background-position-y: 90%;
      padding-bottom: 75px;
      background-image: url("/images/about-bg-mobile.svg");
    }

    @media screen and (min-width: 1600px) {
      background-size: 60% 50%;
      background-position-x: 57%;
    }

    .banner {
      color: white;
      padding-bottom: 256px;
      font-size: 64px;
      text-align: center;

      @media screen and (max-width: 767px) {
        text-align: left;
        font-size: 36px;
        padding-bottom: 240px;
      }
    }

    .container {
      padding-top: 120px;
      padding-bottom: 240px;

      @media screen and (max-width: 768px) {
        padding-top: 40px;
        padding-bottom: 12px;
      }
    }
  }

  .second-block {
    background: white;

    .container {
      padding-top: 100px;
      padding-bottom: 58px;

      @media screen and (max-width: 768px) {
        padding-top: 58px;
        padding-bottom: 54px;
      }

      .title {
        font-size: 48px;
        font-weight: bold;
        color: rgb(15, 98, 155);
        text-align: left;
        padding-bottom: 60px;

        @media screen and (max-width: 768px) {
          padding-bottom: 40px;
          font-size: 30px;
        }
      }

      .copy {
        font-size: 21px;

        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }

  .third-block {
    padding-bottom: 24px;

    a {
      color: #2e3f56;

      &:hover {
        color: #2e3f56;
      }
    }

    .img-fluid {
      border: 1px solid #f0f0f0;
      border-radius: 4px;

      @media (max-device-width: 767px) {
        margin-bottom: 12px;
      }
    }

    .profile {
      padding-bottom: 48px;

      @media screen and (max-width: 768px) {
        padding-bottom: 84px;
        text-align: center;
      }

      .bio {
        text-align: left;
        font-size: 21px;
        color: black;

        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }

      .linkedin {
        color: #0077b5;
      }

      .name {
        font-size: 21px;
        color: #0f629b;
        font-weight: bold;
      }

      .title {
        font-weight: bold;
        color: #0f629b;
      }
    }
  }

  .fourth-block {
    padding-bottom: 24px;
    background: white;
    text-align: center;

    .header {
      color: #0f629b;
      font-size: 24px;
      font-weight: bold;

      @media screen and (max-width: 767px) {
        text-align: left;
        font-size: 36px;
        padding-bottom: 240px;
      }
    }

    .sub-head {
      color: rgb(104, 104, 104);
      text-align: center;
      font-size: 20px;
      padding-bottom: 20px;

      @media screen and (max-width: 767px) {
        text-align: left;
        font-size: 36px;
        padding-bottom: 240px;
      }
    }

    .learn-more-button {
      padding-top: 24px;
      padding-bottom: 24px;
      width: 200px;
      color: white;
      background-color: #27DBEC;
      border-color: #27DBEC;
      font-size: 20px;



      @media screen and (max-width: 767px) {
        text-align: left;
        font-size: 36px;
        padding-bottom: 240px;
      }
    }

    @media screen and (max-width: 768px) {
      padding-top: 40px;
      padding-bottom: 12px;
    }
  }
}
