.blue-nav {
  background: #0f639b;

  .navbar {
    padding: 0px 15px;
  }

  .nav-link {
    color: white;
    margin-right: 42px;
    font-size: 16px;
    padding-top: 45px;
    padding-bottom: 45px;
  
    &.active {
      border-top: 3px solid white;
      padding-top: 42px;
      font-weight: bold;
    }
  }

  .get-started {
    border: 2px solid white;
    border-radius: 26px;
    padding: 15px 48px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: #0f629b;
      background: white;
    }
  }
}

.white-nav {
  background: white;
  position: relative;
  z-index: 1;

  .navbar {
    padding: 0px 15px;
    margin-top: -1px;
  }

  .nav-link {
    color: black;
    margin-right: 42px;
    font-size: 16px;
    padding-top: 45px;
    padding-bottom: 45px;
  
    &.active {
      border-top: 3px solid #0f629b;
      color: #0f629b;
      padding-top: 42px;
      font-weight: bold;
    }
  }

  .get-started {
    border: 2px solid #27dbec;
    color: white;
    background: #27dbec;
    border-radius: 26px;
    padding: 15px 48px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: #27dbec;
      background: white;
    }
  }
}

.bm-menu {
  background: #535353;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-overlay {
  background: transparent !important;
  top: 0;
  z-index: 10050 !important;
}

.bm-menu-wrap {
  z-index: 10070 !important;
  top: 0;
  display: hidden;
}

.bm-burger-button {
  display: none;
}

.menu-item {
  padding-left: 10px;
  font-size: 24px;
  color: white;
  padding-bottom: 15px;
}

.navbar-brand {
  cursor: pointer;
}

.mobile-nav {
  background-color: #fffefe;
  padding-top: 12px;
  padding-bottom: 12px;

  .fa {
    &:hover {
      cursor: pointer;
    }
  }
}

.menu-item.active {
  background: none !important;
  color: #0f619a !important;
  border-left: 4px solid #0f619a;
  margin-left: -4px;
}

.menu-item {
  padding-left: 10px;
  font-size: 21px;
  color: #3b4145;
  margin-bottom: 15px;
  background: none !important;
  font-weight: 500;
  cursor: pointer;
}

.public-mobile-nav {
  background-color: #fffefe;
  padding-top: 16px;
  padding-bottom: 16px;
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  text-align: center;

  .menu-item {
    &.active {
      margin-left: 0;
      border-right: 8px solid;
      border-left: 8px solid;
    }
  }

  .fa {
    &:hover {
      cursor: pointer;
    }
  }
}

.scroll-nav {
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #d6d6d6;
  background: white;
  padding: 16px 0px;
  z-index: 1000;
  opacity: 0.98 !important;
  animation: fadeIn 400ms ease-in-out;
  visibility: visible;
  box-shadow: -0.7px 1.9px 5px 0 rgba(32, 39, 49, 0.15);

  img {
    cursor: pointer;
  }

  .btn {
    width: 150px;
  }

  .nav-nav {
    border-radius: 0px;
    background: white;
    margin-bottom: 0px;
    z-index: 500;
    padding-left: 0px;
    padding-right: 0px;
  
    .nav-link {
      color: #646f79;
      font-weight: normal;
      letter-spacing: 0.3px;
      padding-top: 0px;
      padding-bottom: 0px;
  
      &.active {
        color: #0f629b;
        font-weight: bold;
        border-top: 3px solid #0f629b;
        margin-top: -23px;
        padding-top: 20px;
      }
  
      &:hover {
        color: #0f629b;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-69px);
  }
  100% {
    opacity: 0.98;
    transform: translateY(0px);
  }
}

.hidden-nav {
  visibility: hidden;
  height: 0px;
}