@media print {
  @page {
    size: letter;
  }

  .page-break-clear { 
    clear: both;
  }
  
  .page-break {
    page-break-before: always;
    margin-top: 36px;
    // border-top: 1px solid gray;

    .cell {
      border-top: 1px solid gray !important;
    }
  }

  .row {
    display: block !important;
    height: 100%;
    width: 100%;
  }

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    &:before {
      vertical-align: middle;
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    margin-top: -8px;
    height: 100%;
    display: inline-block !important; }
}

.Report {
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  margin-top: 36px;

  .container-fluid {
    padding: 0px 15px;
  }

  .small {
    font-size: 14px;
  }

  .header {
    padding-bottom: 12px;
    border-bottom: 1px solid grey;
    margin-bottom: 12px;
  }

  .profile {
    padding-bottom: 8px;
  }

  .profile-cell {
    text-align: center;
    padding: 8px 2px;

    .cell {
      border: 1px solid grey;
      border-radius: 4px;

      .profile-value {
        padding: 6px 0px;
        min-height: 18px;
      }

      .title {
        font-size: 14px;
        font-weight: bold;
        border-bottom: 1px solid grey;
        background: #f6f6f6;
        border-radius: 4px 4px 0 0;
        min-height: 17px;
      }
    }
  }

  .my-list {
    .head {
      font-size: 9px;
      font-weight: bold;
      border: 1px solid rgb(127, 127, 127);
      border-radius: 4px 4px 0 0;
      text-align: center;
      padding-top: 1px;
    }

    .school-row {
      margin-top: -1px;
      border-top: none;
      border-left: 1px solid white;
      border-right: 1px solid white;
    }

    .cell {
      font-size: 14px;
      border: 1px solid grey;
      border-top: none;
      text-align: center;
      padding: 4px 1px;

      p {
        margin: 0;
        text-align: center;
      }
    }

    .dot {
      width: 7px;
      height: 7px;
      position: absolute;
      background: green;
      border-radius: 50%;
      margin-left: -10px;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }
}