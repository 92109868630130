.Counselors {
  .first-block {
    background: #0f639b;
    background-image: url('/images/cta.svg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    padding-bottom: 160px;

    @media (min-device-width: 1600px) {
      background-image: url('/images/cta.svg');
      background-size: 100%;

    }

    @media (max-device-width: 768px) {
      background-image: none;
      text-align: center;
      padding-bottom: 0px;
      padding-top: 40px;
    }
  
    .container {
      padding-top: 100px;
      padding-bottom: 120px;
      text-align: center;

      .big-text {
        font-size: 52px;

        @media screen and (max-width: 768px) {
          font-size: 42px;
          text-align: center;
        }
      }

      .title {
        font-size: 64px;
        font-weight: bold;
        line-height: 1.22;

        @media screen and (max-width: 768px) {
          font-size: 28px;
          text-align: center;
        }
      }

      .subtitle {
        font-size: 20px;
        margin-bottom: 64px;
        text-align: center;

        @media screen and (max-width: 768px) {
          font-size: 16px;
          text-align: center;
          margin-top: 24px;
        }
      }

      @media screen and (max-width: 768px) {
        padding-top: 60px;
        padding-bottom: 120px;
        text-align: center;

      }
    }
  }

  .features-block {
    background-image: url('/images/fifth-block.svg');
    background-size: 100%;
    background-position: top;
    text-align: center;
    background-repeat: no-repeat;

    img {
      border: 1px solid #d6d6d6;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px 0px;
      cursor: pointer;
      width: 85%;
      border-radius: 4px;
    }
    
    .container-fluid, .container {
      padding-top: 80px;
      padding-bottom: 106px;

      @media screen and (max-width: 768px) {
        padding-top: 60px;
        padding-bottom: 80px;
      }

      .features {
        padding-bottom: 1.5rem;
        font-size: 48px;
        font-weight: bold;
        color: #0f639c;
      }

      .title {
        padding-bottom: 12px;
        font-weight: bold;
        font-size: 21px;
        color: #383838;
      }

      .copy {
        margin-top: 12px;
        width: 85%;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 768px) {
          padding-bottom: 56px;
        }
      }

      .col-left {
        padding-left: 30px;
      }

      .col-right {
        padding-right: 30px;
      }
    }
  }


  .CounselorEndBlockCTA {
    color: #0f639b;
    text-align: center;
    padding-top: 24px;
    padding-bottom: 64px;
  
    @media screen and (max-width: 768px) {
      padding-top: 130px;
      padding-bottom: 80px;
    }

    .title {
      margin-bottom: 0.5rem;
      font-family: inherit;
      font-weight: 500;
      color: #0f639b;
      font-size: 30px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        font-weight: bold;
      }
    }
  
    .inside-cta {
      background-image: url('/images/ctamodified.jpg');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: bottom;
      text-align: center;
      padding: 36px 24px 60px;
      border: 1px solid rgb(214, 214, 214);
      border-radius: 4px;
      margin-top: -64px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px 0px;

      @media screen and (max-width: 768px) {
        box-shadow: none;
        border: none;
        background-image: none;
        padding: 0px 15px;
        border-radius: none;
      }
    }
  }

  .CopyBlock {
    background: #faf4f4;
    padding-top: 80px;
    padding-bottom: 108px;
    text-align: center;

    @media screen and (max-width: 768px) {
      padding-top: 60px;
      padding-bottom: 80px;
    }

    h4 {
      font-weight: bold;
    }

    .fa {
      margin-bottom: 24px;
      color: #0f639c;
      background: white;
      padding: 24px;
      border-radius: 50%;
      border: 2px dashed #d6d6d6;
    }

    .content {
      @media screen and (max-width: 768px) {
        &:not(:first-child) {
          margin-top: 36px;
        }
      }
    }
  }

  .block {
    display: block;

    @media screen and (max-width: 768px) {
      display: unset;
    }
  }
}