@import "./bootstrap.css";

html {
  font-size: 93.75%;
}

.spacer {
  min-height: 70px;
}

.footer-spacer {
  color: white;
  min-height: 122px;

  @media (max-device-width: 767px) {
    height: 715px;
  }
}

.body {
  overflow-x: hidden;
  width: 100vw;
}

.s-alert-error {
  background: #dc3545;
  color: white;
  font-weight: 500;
}

.s-alert-warning {
  background: #49bdc8;
  color: white;
  font-weight: 500;
};

.s-alert-box {
  z-index: 12000;
  right: 32px !important;

  @media screen and (max-width: 768px) {
    right: 16px !important;
  }
}

.s-alert-success {
  background: #0f629b;
  color: white;
  font-weight: 500;
}

.s-alert-info {
  background: #27dbec;
  color: white;
  font-weight: 500;
}

.content-grey-border {
  background: #f6f6f6 !important;
  border-bottom: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;
}

.align-center {
  text-align: center;
}

::-webkit-scrollbar {
  width: 10px;
  height: 15px;
  background: white;
}

::-webkit-scrollbar:vertical {
  border-left: 1px solid #d6d6d6;
}

::-webkit-scrollbar:horizontal {
  border-top: 1px solid #ddd;
}

::-webkit-scrollbar-thumb {
    border: 0px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}

.content-grey-background {
  background: #f6f6f6 !important;
  border-bottom: 1px solid #d6d6d6;
}

.absolute-center {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
}

.fake-link {
  color: #0f629b;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.grecaptcha-badge {
  visibility: hidden;
  height: 0px !important;
}

.tooltip-inner {
  border: 1px solid #ddd;
}

.tooltip .tooltip-inner::before {
  border-top-color: #ddd;
}

.tooltip .tooltip-inner::before {
  border-bottom-color: #ddd;
}

.tooltip .tooltip-inner::before {
  border-right-color: #ddd;
}

.tooltip.tooltip-inner::before {
  border-left-color: #ddd;
}

.hide-mobile {
  @media screen and (max-width: 768px) {
    height: 0px;
    width: 0%;
    visibility: hidden;
    flex: none;
    position: absolute;
    z-index: -1000;
  }
}

.calendly-inline-widget {
  border: none;
  box-shadow: none;
}

.toast-container {
  .Toastify__close-button {
    color: white;
  }

  .Toastify__toast {
    font-weight: bold;
    border-radius: 4px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
  }

  .Toastify__toast--success {
    background: #0f629b;
  }

  .Toastify__toast--error {
    background: #dc3545;
    color: white;

    .Toastify__close-button {
      color: white;
    }
  }
}
