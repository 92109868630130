.WebinarSchedules {
    padding-top: 80px;
    padding-bottom: 106px;

    @media screen and (max-width: 768px) {
      padding-top: 60px;
      padding-bottom: 80px;
    }

    .title {
      padding-bottom: 1.5rem;
      font-size: 48px;
      font-weight: bold;
      color: #0f639c;
      text-align: center;
    }
}