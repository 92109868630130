.CollegeSearchDropdown {
  position: relative;

  .form-control {
    padding: 12px 15px;
  }
}

.clear-icon {
  position: absolute;
  top: 8px;
  right: 3px;
}

.search-results {
  position: absolute;
  left: 0;
  box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.5);
  z-index: 10;
  min-width: 100%;
  max-height: 500px;
  overflow-y: auto;

  .search-cell {
    width: 100%;
    border: 1px solid #d6d6d6;
    padding: 8px;
    cursor: pointer;
    background: white;
    text-align: left;

    &:nth-of-type(odd) {
      background: #f6f6f6;
    }

    &:hover {
      .name {
        text-decoration: underline;
      }
    }

    &:not(:last-child) {
      border-bottom: none;
    }

    .name {
      color: #3b4145;
      font-weight: bold;
    }

    .location {
      font-size: 14px;
      color: #3b4145;
    }
  }
}