.Index {
  .first-block {
    background: #0f639b;
    background-image: url('/images/hero.svg');
    background-size: 70% auto;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 70%;

    @media (min-device-width: 1600px) {
      background-size: 70% 100%;
      background-position: bottom right;
    }

    @media (max-device-width: 768px) {
      background-image: url('/images/hero-mobile.svg');
      background-size: 100%;
      background-position-y: 90%;
      background-position-x: 100%;
      text-align: center;
      background-size: 70% auto;
    }

    @media (max-device-width: 576px) {
      background-image: url('/images/hero-mobile.svg');
      background-size: 100%;
      background-position-y: 80%;
      text-align: center;
    }
  
    .container {
      padding-top: 64px;
      padding-bottom: 160px;

      .big-text {
        font-size: 64px;

        @media screen and (max-width: 768px) {
          font-size: 48px;
        }
      }

      .title {
        font-size: 64px;
        font-weight: bold;
        line-height: 1.22;

        @media screen and (max-width: 768px) {
          font-size: 28px;
        }
      }

      .subtitle {
        font-size: 20px;
        margin-bottom: 36px;
        text-align: left;

        @media screen and (max-width: 768px) {
          font-size: 16px;
          text-align: center;
        }
      }

      @media screen and (max-width: 768px) {
        padding-top: 60px;
        padding-bottom: 620px;
      }
    }
  }

  .second-block {
    background: white;
    background-image: url('/images/second-block.svg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: bottom right;

    @media screen and (max-width: 768px) {
      background-image: none;
    }
  
    .container {
      padding-top: 64px;
      padding-bottom: 120px;

      @media screen and (max-width: 768px) {
        padding-top: 72px;
        padding-bottom: 40px;
      }

      .video-wrapper {
        border: 1px solid #eae4e4;
        // border-radius: 14px;
        overflow: hidden;
        // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
        margin-bottom: 24px;
        z-index: 2;
        position: relative;
        background: white;
      }

      .paw-right {
        font-size: 160px;
        position: absolute;
        margin-top: -200px;
        right: -140px;
        transform: rotate(33deg);
        color:#f7f6f6;
      }

      .paw-left {
        font-size: 200px;
        position: absolute;
        margin-top: -400px;
        left: -170px;
        transform: rotate(-42deg);
        color: #eae4e4;
      }

      .content-image {
        height: 100%;
        width: auto;

        @media screen and (max-width: 768px) {
          height: unset;
          width: 100%;
        }
      }
      
      .title {
        font-size: 48px;
        font-weight: bold;
        color: rgb(15, 98, 155);
        text-align: center;
        padding-bottom: 36px;

        @media screen and (max-width: 768px) {
          font-size: 30px;
          background: white;
        }
      }

      .subtitle {
        font-size: 36px;
        padding-bottom: 24px;
        font-weight: bold;

        @media screen and (max-width: 768px) {
          font-size: 24px;
        }
      }

      .copy {
        font-size: 18px;

        @media screen and (max-width: 768px) {
          font-size: 18px;
          color: #3b4145;
          padding-bottom: 18px;
        }
      }
    }
  }

  .third-block {
    background: #faf4f4;
    background-image: url('/images/third-block.svg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: bottom;

    @media screen and (max-width: 768px) {
      background-image: unset;
    }
  
    .container {
      padding-top: 60px;
      padding-bottom: 60px;

      @media screen and (max-width: 768px) {
        padding-top: 80px;
        padding-bottom: 40px;
      }

      .subtitle {
        font-size: 36px;
        padding-bottom: 24px;
        font-weight: bold;

        @media screen and (max-width: 768px) {
          font-size: 24px;
        }
      }

      .copy {
        font-size: 16px;

        @media screen and (max-width: 768px) {
          font-size: 18px;
          color: #3b4145;
          padding-bottom: 18px;
        }
      }

      .content-image {
        width: 80%;

        @media screen and (max-width: 768px) {
          height: unset;
          width: 100%;
        }
      }
    }
  }

  .fourth-block {
    background: white;
    background-image: url('/images/fourth-block.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-position-x: 35%;
    background-size: 257px;
  
    @media screen and (max-width: 768px) {
      background-image: unset;
    }
  
    .container {
      padding-top: 80px;
      padding-bottom: 80px;

      @media screen and (max-width: 768px) {
        padding-top: 80px;
        padding-bottom: 40px;
      }

      .subtitle {
        font-size: 36px;
        padding-bottom: 24px;
        font-weight: bold;

        @media screen and (max-width: 768px) {
          font-size: 24px;
        }
      }

      .copy {
        font-size: 16px;

        @media screen and (max-width: 768px) {
          font-size: 18px;
          color: #3b4145;
          padding-bottom: 18px;
        }
      }

      .content-image {
        height: auto;
        width: 100%;

        @media screen and (max-width: 768px) {
          height: unset;
          width: 100%;
        }
      }
    }
  }

  .fifth-block {
    background: white;
    
    strong {
      color: #0f629b;
    }

    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230f629b' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
     }
     
    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230f629b' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
    }

    .carousel-indicators li {
      background-color: #0f629b;
      opacity: 0.5;
    }

    .carousel-indicators .active {
      background-color: #0f629b;
      opacity: 1.0;
    }

    .copy {
      font-size: 36px;
      text-align: center;
      color: rgb(59, 65, 69);

      @media screen and (max-width: 768px) {
        font-size: 24px;
      }
    }

    .content {
      height: unset;
      visibility: visible;
      text-align: center;

      @media screen and (max-width: 768px) {
        visibility: hidden;
        height: 0;
      }
    }

    .content-image {
      margin-top: 60px;

      @media screen and (max-width: 768px) {
        visibility: hidden;
        height: 0;
      }
    }

    .container {
      padding-top: 80px;

      @media screen and (max-width: 768px) {
        padding-top: 76px;
        padding-bottom: 83px;
      }
    }
  }

  .sixth-block {
    background: white;
    background-image: url('/images/about-bg.svg');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: bottom left;
    min-height: 500px;
    padding-top: 40px;

    @media screen and (max-width: 768px) {
      background-image: unset;
      min-height: 0px;
      padding-top: 0px;
    }
    
    .container-fluid {
      padding-top: 64px;
      padding-bottom: 106px;

      @media screen and (max-width: 768px) {
        padding-top: 30px;
        padding-bottom: 80px;
      }
        
      .FreeTrialForm {
        padding-left: 15px;
        padding-right: 15px;
      }
      
        // padding: 0px 15px 24px 15px;
        // background: white;
        // border: 1px solid #ddd;
        // border-radius: 14px;
        // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
      

        // .trial-body {
        //   padding: 15px 30px;
        // }

        // h4 {
        //   font-weight: bold;
        //   font-size: 24px;
        //   background: rgb(246, 246, 246);
        //   border-radius: 14px 14px 0px 0px;
        // }

        // .header-col {
        //   background: #f6f6f6;
        //   border-bottom: 1px solid #ddd;
        //   margin-bottom: 16px;
        //   border-radius: 14px 14px 0px 0px;
        //   padding-top: 16px;
        // }
      // }

      .title {
        padding-bottom: 24px;
        margin-bottom: 36px;
        color: rgb(15, 98, 155);
        font-weight: bold;
        font-size: 48px;
  
        @media screen and (max-width: 768px) {
          font-size: 30px;
        }
      }

      .copy {
        @media screen and (max-width: 768px) {
          padding-bottom: 56px;
        }
      }
    }
  }

  .seventh-block {
    background: #faf4f4;
    background-image: url('/images/fifth-block.svg');
    background-size: 100%;
    background-repeat: no-repeat;

    @media screen and (max-width: 768px) {
      background-image: unset;
    }
    
    .container {
      padding-top: 80px;
      padding-bottom: 106px;
      text-align: center;

      @media screen and (max-width: 768px) {
        padding-top: 30px;
        padding-bottom: 80px;
      }
        
      .FreeTrialForm {
        padding-left: 15px;
        padding-right: 15px;
      }
      
        // padding: 0px 15px 24px 15px;
        // background: white;
        // border: 1px solid #ddd;
        // border-radius: 14px;
        // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
      

        // .trial-body {
        //   padding: 15px 30px;
        // }

        // h4 {
        //   font-weight: bold;
        //   font-size: 24px;
        //   background: rgb(246, 246, 246);
        //   border-radius: 14px 14px 0px 0px;
        // }

        // .header-col {
        //   background: #f6f6f6;
        //   border-bottom: 1px solid #ddd;
        //   margin-bottom: 16px;
        //   border-radius: 14px 14px 0px 0px;
        //   padding-top: 16px;
        // }
      // }

      .title {
        padding-bottom: 24px;
        margin-bottom: 36px;
        color: rgb(15, 98, 155);
        font-weight: bold;
        font-size: 48px;
  
        @media screen and (max-width: 768px) {
          font-size: 30px;
        }
      }

      .copy {
        padding-bottom: 24px;

        @media screen and (max-width: 768px) {
          padding-bottom: 56px;
        }
      }
    }
  }

  .test-widget {
    background: transparent;
    z-index: 10;
    margin-top: -60px;

    @media screen and (max-width: 768px) {
      padding: 0px;
    }

    .widget {
      background: white;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);

      @media screen and (max-width: 768px) {
        border-radius: 0px;
      }

      .dog-wrapper-class {
        display: inline-block;
      }

      .dog-btn {
        fill: white;
      }

      .btn:hover {
        .dog-btn {
          fill: #0f629b;
        }
      }

      .widget-input {
        @media screen and (max-width: 768px) {
          padding-bottom: 1rem;
        }
      }

      .widget-radio {
        text-align: left;

        @media screen and (max-width: 768px) {
          text-align: center;
        }
      }

      .desktop-run-btn {
        visibility: visible;
        height: unset;

        @media screen and (max-width: 768px) {
          visibility: hidden;

          .btn {
            width: 0px;
          }
        }
      }

      .initial-run-btn {
        padding-top: 24px;
        padding-bottom: 1rem;
        text-align: center;

        @media screen and (max-width: 768px) {
          padding-top: 8px;
          padding-bottom: 1rem;
        }
      }

      .mobile-run-btn {
        visibility: hidden;
        height: 0px;

        @media screen and (max-width: 768px) {
          visibility: visible;
          height: unset;
          padding-top: 12px;
        }
      }

      .college-cell {
        background: white;
        margin-bottom: 17px;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 4px;
        overflow: hidden;
        padding: 4px 0;
        margin-top: 24px;
        border: 1px solid #ddd;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
        min-height: 58px;
        max-width: calc(25% - 30px) !important;
        margin-left:15px;
        margin-right: 15px;

        @media screen and (max-width: 768px) {
          margin-top: 0px;
          max-width: 100% !important;
          margin-left: 0px;

          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }

        .remove-button {
          padding: 5px 10px;
          font-size: 24px;
          line-height: 21px;
          font-weight: bold;
        }

        .score-box {
          min-height: 100%;
          color: white;
          font-weight: bold;
          line-height: 45px;
          font-size: 17px;
          text-align: center;
        }

        .score-color-background {
          background: #eee;
          position: absolute;
          height: 10000px;
          width: 100%;
          top: -200px;
          left: 0;
        }

        .school-name {
          font-weight: bold;
          padding-left: 27px;
        }
      }
    }
  }

  .college-block {
    background: #0f629b;
    color: white;
    background-image: url('/images/hero-2.svg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: bottom right;

    @media screen and (max-width: 768px) {
      background-image: none;
    }
  
    .container {
      padding-top: 80px;
      padding-bottom: 106px;
      text-align: center;

      @media screen and (max-width: 768px) {
        padding-top: 30px;
        padding-bottom: 80px;
      }

      .search-btn {
        color: #b8c1ca;
        position: absolute;
        top: 24px;
        right: 15px;
        cursor: pointer;
        transition: color 0.15s ease-in-out;

        &:hover {
          color: #ddd;
        }
      }

      .college-card {
        color: #3b4145;
        background: white;
        margin-top: 16px;
        border-radius: 4px;
        padding-top: 15px;
        text-align: left;

        .title {
          font-size: 18px;
          font-weight: bold;
        }

        .stat-col {
          padding-top: 6px;
        }
      }
    }
  }
  .customers-block {
    background: whitesmoke;
    padding-top: 40px;
    padding-bottom: 40px;

    .container {
      text-align: center;
    }

    img {
      mix-blend-mode: multiply;

      @media screen and (max-width: 768px) {
        max-height: 76px;
      }
    }
  }
  .partners-block {
    background: whitesmoke;
    padding-top: 40px;
    padding-bottom: 40px;

    .container {
      text-align: center;
    }

    img {
      mix-blend-mode: multiply;
      
      @media screen and (max-width: 768px) {
        max-height: 52px;
      }
    }
  }
}

.block {
  display: block;

  @media screen and (max-width: 768px) {
    display: unset;
  }
}