

.Contact {
  color: black;
  padding-bottom: 120px;

  .banner {
    color: #0f629b;
    font-weight: bold;
    font-size: 48px;
    padding-top: 60px;
    padding-bottom: 16px;
  }

  .btn {
    padding: 15px 48px;
    margin-top: 48px;
  }
}