.Plans {
  background: #fafafa;
  border: 1px solid #eee;
  border-radius: 3px;

  .Plan {
    display: block;
    padding: 15px;
    margin: 0;

    input {
      margin-right: 10px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    .custom-radio {
      cursor: pointer;
    }

    .custom-control-description {
      padding-left: 12px;
    }

    .custom-control-indicator {
      top: auto;
      left: 12px;
    }
  }

  .Plan:hover {
    cursor: pointer;
    background: #f3f3f3;
  }
}