.Blog {
  min-height: calc(100vh - 114px);
  
  .iframe {
    position: absolute;
    margin-top: -30px;
    z-index: -1;
    left: 0
  }

  .title {
    padding-bottom: 8px;
    font-size: 48px;
    color: #0f629b;
    font-weight: bold;

    @media screen and (max-width: 768px) {
			font-size: 32px;
		}
  }

  .blog-title {
    padding-bottom: 8px;
    font-size: 30px;
    color: #0f629b;

    @media screen and (max-width: 768px) {
			font-size: 32px;
		}
  }

  .blog-date-line {
    padding-bottom: 8px;
    font-size: 15px;
    color: #8b9397;

    @media screen and (max-width: 768px) {
			font-size: 32px;
		}
  }

  .blog-body {
    padding-top: 8px;
    padding-bottom: 0px;
    font-size: 20px;
    color: #000000;

    @media screen and (max-width: 768px) {
			font-size: 32px;
		}
  }

  .blog-link {
    padding-top: 8px;
    padding-bottom: 48px;
    font-size: 20px;
    color: #494949;

    @media screen and (max-width: 768px) {
			font-size: 32px;
		}
  }

  .search-bar {
    width: 350px;
    margin-bottom: 64px;

    @media screen and (max-width: 768px) {
			margin-bottom: 32px;
    }
    
    .clear-search {
      cursor: pointer;

      &:hover {
        color: #27dbec;
      }
    }
  }

  .Featured {
    padding-bottom: 48px;

    @media screen and (max-width: 768px) {
      padding-bottom: 32px;
    }

    .featured-content {
      border: 1px solid rgb(212, 212, 212);
      padding: 0px;

      @media screen and (max-width: 768px) {
        margin-bottom: 16px;
      }
    }

    .featured-thumbnail {
      margin-bottom: 20px;
      height: 174px;
      overflow: hidden;

      @media screen and (max-width: 768px) {
        height: unset;
      }
    }

    .featured-text {
      padding-left: 24px;
      padding-right: 24px;
      font-size: 16px;
      color: rgb(59, 65, 69);
      margin-bottom: 24px;
      min-height: 120px;

      @media screen and (max-width: 768px) {
        min-height: unset;
      }
    }

    .featured-title {
      padding-left: 24px;
      padding-right: 24px;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 12px;

      a {
        color: #3b4145;
      }

      @media screen and (max-width: 768px) {
        font-size: 18px;
        color: #0f629b;
      }
    }
  }

  .PostList {
    .postlist-image {
      @media screen and (max-width: 768px) {
        visibility: hidden;
        height: 0px;
      }
    }

    .post-cell {
      border-top: 1px solid #a9b2b7;
      margin-top: -1px;
      padding-top: 64px;
      padding-bottom: 64px;

      @media screen and (max-width: 768px) {
        padding-top: 32px;
        padding-bottom: 32px;
      }

      &:last-child {
        border-bottom: 1px solid #a9b2b7;
      }

      a {
        font-weight: bold;
        color: #27dbec;
      }
    }
  }
}

.SearchList {
  min-height: 300px;

  .postlist-image {
    @media screen and (max-width: 768px) {
      visibility: hidden;
      height: 0px;
    }
  }

  .post-wrapper {
    border-bottom: 1px solid #a9b2b7;    

    &:last-child {
      border-bottom: none;
      margin-bottom: 74px;
    }
  }

  .post-cell {
    padding-top: 64px;
    padding-bottom: 64px;

    @media screen and (max-width: 768px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    a {
      font-weight: bold;
      color: #27dbec;
    }
  }
}