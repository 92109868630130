.EndBlockCTA {
  background: #0f639c;
  color: white;
  padding-top: 104px;
  padding-bottom: 240px;
  background-image: url('/images/cta.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;

  @media screen and (max-width: 767px) {
    padding-top: 64px;
    padding-bottom: 272px;
    background-image: url('/images/cta-mobile.svg');
  }

  .title {
    margin-bottom: 38px;
    font-size: 48px;
    font-weight: bold;

    @media screen and (max-width: 768px) {
      font-size: 28px;
    }
  }

  .FreeTrialForm {
    color: white;

    a {
      color: #27dbec;
      font-weight: bold;
    }
  }
}